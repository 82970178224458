import { CloseButton, Flex, useColorModeValue, Box } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

export const MobileNav: React.FC<Props> = props => {
  const { isOpen, onClose, children } = props;
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          transition={{ duration: 0.3 }}
          initial={{ opacity: 0, height: 0, top: 0 }}
          animate={{ opacity: 1, height: "auto", top: 70 }}
          exit={{ opacity: 0, height: 0, top: 0 }}
        >
          <Flex
            direction="column"
            overflow="auto"
            border="2px solid white"
            sx={{ maxWidth: "200px" }}
            pos="absolute"
            left={8}
            zIndex={20}
            background="primary"
            borderRadius="10px"
            boxShadow="8px 8px 5px black"
          >
            {children}
          </Flex>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
