import React from "react";
import { Helmet } from "react-helmet";

const DataUnlocker = () => (
  <Helmet>
    <script src="static/du.js" />
  </Helmet>
);

export default DataUnlocker;
