import * as React from "react";
import { Box, Center, Flex } from "@chakra-ui/react";

import DataUnlocker from "atoms/DataUnlocker";
import { Navbar } from "components/organisms/navbar";
import { Footer } from "organisms/footer";

import "../fonts/fonts.css";

const Template: React.FC = ({ children, ...rest }) => {
  return (
    <>
      <DataUnlocker />
      <Flex flexDirection="column" w="100vw" h="100vh">
        <Navbar />
        <Box
          overflowY="auto"
          bg="white"
          color="primary"
          w="100vw"
          pt="6"
          {...rest}
        >
          <Center flex="1" alignItems="flex-start" minHeight="100vh" px="5">
            <Box>{children}</Box>
          </Center>
          <Footer />
        </Box>
      </Flex>
    </>
  );
};

export default Template;
