import * as React from "react";
import { Link } from "gatsby";
import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  IconButton,
  Text,
  useDisclosure,
  Spacer
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars } from "@fortawesome/pro-regular-svg-icons";

import { Logo } from "components/atoms/Logo";
import { MobileNav } from "components/atoms/MobileNav";

const NavLink = ({ to, children }) => (
  <a href={to}>
    <Text _hover={{ color: "secondary", cursor: "pointer" }}>{children}</Text>
  </a>
);

const MobileNavLink = ({ to, children }) => (
  <Box px={4} py={2} _hover={{ background: "rgb(128,90,213)" }}>
    <Link to={to}>
      <Text _hover={{ cursor: "pointer" }}>{children}</Text>
    </Link>
  </Box>
);

export const Navbar: React.FC = ({}) => {
  const mobileNav = useDisclosure();
  return (
    <Box>
      <Box
        as="header"
        bg="primary"
        borderBottomWidth="1px"
        color="white"
        fontFamily="Montserrat"
      >
        <Box maxW="7xl" mx="auto" py="4" px={{ base: "6", md: "8" }}>
          <Flex as="nav" align="center" justify="space-between">
            <HStack spacing="8">
              <Stack>
                <IconButton
                  display={{ base: "flex", lg: "none" }}
                  size="sm"
                  aria-label="Open menu"
                  fontSize="20px"
                  variant="whiteIcon"
                  onClick={mobileNav.onToggle}
                  icon={<FontAwesomeIcon icon={faBars} />}
                />
                <Box>
                  <MobileNav
                    isOpen={mobileNav.isOpen}
                    onClose={mobileNav.onClose}
                  >
                    <Stack background="primary" py={4}>
                      <MobileNavLink to="/home?new=true">
                        Write a new story
                      </MobileNavLink>
                      <MobileNavLink to="/home">Browse stories</MobileNavLink>
                      <MobileNavLink to="/help">Help</MobileNavLink>
                      <MobileNavLink to="/sign-in">
                        Register/Sign in
                      </MobileNavLink>
                    </Stack>
                  </MobileNav>
                </Box>
              </Stack>
              <Box as="a" href="#" rel="home">
                <Link to="/">
                  <Logo size="100px" />
                </Link>
              </Box>
              <HStack display={{ base: "none", lg: "flex" }} spacing="8">
                <NavLink to="/home?new=true">Write a new story</NavLink>
                <NavLink to="/home">Browse stories</NavLink>
                <NavLink to="/help">Help</NavLink>
              </HStack>
            </HStack>
            <Spacer />
            <NavLink to="/sign-in">Register / Sign In</NavLink>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
