import * as React from "react";
import { Link } from "gatsby";
import {
  Box,
  Stack,
  ButtonGroup,
  ButtonGroupProps,
  Link as ChakraLink,
  IconButton,
  Text
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitter,
  faDiscord
} from "@fortawesome/free-brands-svg-icons";

import { Logo } from "atoms/Logo";

export const Footer = props => (
  <Box
    as="footer"
    role="contentinfo"
    w="100%"
    py="1"
    px={{ base: "4", md: "8" }}
    borderTopWidth="1px"
    bg="cafe_noir"
    color="white"
    fontFamily="Montserrat"
  >
    <Stack
      direction="row"
      spacing="4"
      align="center"
      justify="space-between"
      w="100%"
    >
      <Stack direction="row" spacing="6" align="center">
        <Link to="/privacy">Privacy</Link>
        <Link to="/terms">Terms and conditions</Link>
      </Stack>
      <Stack direction="row" spacing="4" align="center">
        <ButtonGroup color="white">
          <ChakraLink
            isExternal
            href="https://discord.gg/mcSKgr7K3C"
            aria-label="Discord"
            my={3}
            pr={3}
          >
            <Box bg="cafe_noir" color="white" textAlign="center">
              <FontAwesomeIcon size="lg" icon={faDiscord} />
            </Box>
          </ChakraLink>
          <ChakraLink
            isExternal
            href="https://facebook.com/WrdieOfficial"
            aria-label="Facebook"
            my={3}
            pr={3}
          >
            <Box bg="cafe_noir" color="white" textAlign="center">
              <FontAwesomeIcon size="lg" icon={faFacebookSquare} />
            </Box>
          </ChakraLink>
          <ChakraLink
            isExternal
            href="https://twitter.com/WrdieOfficial"
            aria-label="Facebook"
            my={3}
            pr={3}
          >
            <Box bg="cafe_noir" color="white" textAlign="center">
              <FontAwesomeIcon size="lg" icon={faTwitter} />
            </Box>
          </ChakraLink>
        </ButtonGroup>
      </Stack>
    </Stack>
  </Box>
);
