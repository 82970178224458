import * as React from "react";
import { Box, useTheme } from "@chakra-ui/react";

import { useWindowSize } from "src/hooks/useWindowSize";
import LogoSVG from "components/svg/Logo.svg";

interface OwnProps {
  color?: string;
  size?: "x-small" | "small" | "medium" | "large" | "none";
  width?: string;
}

const widthForSize = {
  "x-small": "60px",
  small: "100px",
  medium: "300px",
  large: "500px",
  none: ""
};

export const Logo: React.FC<OwnProps> = ({ color, size = "none", width }) => {
  const { theme } = useTheme();
  const logoColor = theme?.colors?.[color] || color || "#FFF";
  const [logoWidth, setLogoWidth] = React.useState<string>(
    width || widthForSize[size]
  );

  const { width: windowWidth } = useWindowSize();

  React.useEffect(() => {
    if (size === "large" && windowWidth && windowWidth < 500) {
      setLogoWidth(`${windowWidth - 50}px`);
    }
  }, [windowWidth]);

  return (
    <Box width={size}>
      <LogoSVG width={"100%"} fill={`${logoColor}`} stroke={`${logoColor}`} />
    </Box>
  );
};
